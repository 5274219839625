var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("import-table", {
    ref: "importTableRef",
    attrs: {
      fileHeaders: _vm.fileHeaders,
      rows: _vm.rows,
      fields: _vm.fields,
      items: _vm.destinations,
      importing: _vm.importing,
    },
    on: { mapValues: _vm.mapValues, import: _vm.importDestinations },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }