var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex align-center flex-wrap-reverse justify-space-between",
        },
        [
          _vm.type == "Trips"
            ? _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("v-checkbox", {
                    staticClass: "mx-2 mt-0",
                    attrs: { label: "Hide Split Trips", "hide-details": "" },
                    model: {
                      value: _vm.hideSplitTrips,
                      callback: function ($$v) {
                        _vm.hideSplitTrips = $$v
                      },
                      expression: "hideSplitTrips",
                    },
                  }),
                  _c("v-checkbox", {
                    staticClass: "mx-2 mt-0",
                    attrs: {
                      label: "Hide Third Party Payments",
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.hideThirdPartyPayments,
                      callback: function ($$v) {
                        _vm.hideThirdPartyPayments = $$v
                      },
                      expression: "hideThirdPartyPayments",
                    },
                  }),
                  _c("v-checkbox", {
                    staticClass: "mx-2 mt-0",
                    attrs: { label: "Hide Estimates", "hide-details": "" },
                    model: {
                      value: _vm.hideEstimates,
                      callback: function ($$v) {
                        _vm.hideEstimates = $$v
                      },
                      expression: "hideEstimates",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: {
                color: "primary",
                disabled: _vm.disableImport || _vm.importing,
                loading: _vm.importing,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("import")
                },
              },
            },
            [_vm._v(" Import ")]
          ),
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "overflow-x-auto pa-0", attrs: { fluid: "" } },
        [
          _c(
            "v-simple-table",
            {
              staticClass: "pa-0 grey lighten-4",
              attrs: { "fixed-header": "", height: "600px" },
            },
            [
              _c("thead", [
                _c(
                  "tr",
                  [
                    _c(
                      "th",
                      { staticClass: "text-no-wrap px-1 grey lighten-4" },
                      [_vm._v("Row")]
                    ),
                    _vm._l(_vm.fields, function (field, i) {
                      return _c(
                        "th",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showColumn(field),
                              expression: "showColumn(field)",
                            },
                          ],
                          key: i,
                          staticClass: "text-no-wrap px-1 grey lighten-4",
                          attrs: { width: "300" },
                        },
                        [
                          _vm._v(" " + _vm._s(field.label) + " "),
                          !!field.helper
                            ? _c("v-icon", { on: { click: field.helper } }, [
                                _vm._v("mdi-help-box-multiple-outline"),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
                !!_vm.rows.length
                  ? _c(
                      "tr",
                      { staticClass: "mx-4 text-no-wrap grey lighten-4" },
                      [
                        _c("th", {
                          staticClass: "text-no-wrap grey lighten-4",
                        }),
                        _vm._l(_vm.fields, function (field, i) {
                          return _c(
                            "th",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showColumn(field),
                                  expression: "showColumn(field)",
                                },
                              ],
                              key: i,
                              staticClass: "px-1 grey lighten-4",
                            },
                            [
                              _c("v-select", {
                                staticClass: "text-no-wrap",
                                attrs: {
                                  value: field.mappedTo,
                                  items: _vm.fileHeaders,
                                  "item-text": "label",
                                  "item-value": "value",
                                  dense: "",
                                  "hide-details": "",
                                  outlined: "",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.mapValues(field)
                                  },
                                },
                                model: {
                                  value: field.mappedTo,
                                  callback: function ($$v) {
                                    _vm.$set(field, "mappedTo", $$v)
                                  },
                                  expression: "field.mappedTo",
                                },
                              }),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
              ]),
              _vm._l(_vm.items, function (item, i) {
                return _c(
                  "tr",
                  {
                    key: i,
                    staticClass: "row-separator pa-0 rows",
                    class: { "highlighted-row": i === _vm.selectedRowIndex },
                    on: {
                      click: function ($event) {
                        return _vm.selectRow(i)
                      },
                    },
                  },
                  [
                    _c("td", { staticStyle: { "min-width": "40px" } }, [
                      _vm._v(_vm._s(i + 1) + "."),
                    ]),
                    _vm._l(_vm.fields, function (field, j) {
                      return _c(
                        "td",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showColumn(field),
                              expression: "showColumn(field)",
                            },
                          ],
                          key: j,
                          staticClass: "px-2 py-0",
                          staticStyle: { "min-width": "100px" },
                        },
                        [
                          field.type == "text"
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item[field.value],
                                    expression: "item[field.value]",
                                  },
                                ],
                                staticClass: "regular-input",
                                class: {
                                  "error-border": item.errors.includes(
                                    field.value
                                  ),
                                },
                                attrs: {
                                  maxlength:
                                    _vm.batchEditField.value == "state"
                                      ? 2
                                      : undefined,
                                },
                                domProps: { value: item[field.value] },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        item,
                                        field.value,
                                        $event.target.value
                                      )
                                    },
                                    _vm.validate,
                                  ],
                                },
                              })
                            : field.type == "number"
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item[field.value],
                                    expression: "item[field.value]",
                                  },
                                ],
                                staticClass: "regular-input",
                                class: {
                                  "error-border": item.errors.includes(
                                    field.value
                                  ),
                                },
                                attrs: { type: "number", hideDetails: "" },
                                domProps: { value: item[field.value] },
                                on: {
                                  change: _vm.validate,
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      item,
                                      field.value,
                                      $event.target.value
                                    )
                                  },
                                },
                              })
                            : field.type == "boolean"
                            ? _c("input", {
                                staticClass: "checkbox",
                                class: {
                                  "error-border": item.errors.includes(
                                    field.value
                                  ),
                                },
                                attrs: { type: "checkbox" },
                                domProps: { checked: item[field.value] === 1 },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleChangeCheckbox(field, item)
                                  },
                                },
                              })
                            : field.type == "date"
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item[field.value],
                                    expression: "item[field.value]",
                                  },
                                ],
                                staticClass: "regular-input",
                                class: {
                                  "error-border": item.errors.includes(
                                    field.value
                                  ),
                                },
                                attrs: { type: "date" },
                                domProps: { value: item[field.value] },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        item,
                                        field.value,
                                        $event.target.value
                                      )
                                    },
                                    _vm.validate,
                                  ],
                                },
                              })
                            : field.type == "time"
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item[field.value],
                                    expression: "item[field.value]",
                                  },
                                ],
                                staticClass: "regular-input",
                                class: {
                                  "error-border": item.errors.includes(
                                    field.value
                                  ),
                                },
                                attrs: { type: "time" },
                                domProps: { value: item[field.value] },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        item,
                                        field.value,
                                        $event.target.value
                                      )
                                    },
                                    _vm.validate,
                                  ],
                                },
                              })
                            : field.type.multiple
                            ? _c("v-select", {
                                class: item.errors.includes(field.value)
                                  ? "error-outline"
                                  : "default-select",
                                staticStyle: { "min-width": "100px" },
                                attrs: {
                                  items: field.type.items,
                                  "item-text": "text",
                                  "item-value": "value",
                                  dense: "",
                                  "hide-details": "",
                                  outlined: "",
                                  multiple: "",
                                },
                                on: { change: _vm.validate },
                                model: {
                                  value: item[field.value],
                                  callback: function ($$v) {
                                    _vm.$set(item, field.value, $$v)
                                  },
                                  expression: "item[field.value]",
                                },
                              })
                            : _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: item[field.value],
                                      expression: "item[field.value]",
                                    },
                                  ],
                                  class: item.errors.includes(field.value)
                                    ? "error-outline"
                                    : "default-select",
                                  staticStyle: { "min-width": "100px" },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          item,
                                          field.value,
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      _vm.validate,
                                    ],
                                  },
                                },
                                _vm._l(field.type.items, function (item, k) {
                                  return _c(
                                    "option",
                                    { key: k, domProps: { value: item.id } },
                                    [_vm._v(" " + _vm._s(item.name) + " ")]
                                  )
                                }),
                                0
                              ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
      !!_vm.rows.length
        ? _c(
            "v-row",
            { staticClass: "mt-4", attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "2" } },
                [
                  _c("v-select", {
                    staticClass: "text-no-wrap w-25",
                    attrs: {
                      items: _vm.fields,
                      "item-text": "label",
                      "return-object": "",
                      label: "Field",
                      outlined: "",
                      dense: "",
                    },
                    on: {
                      change: function ($event) {
                        _vm.batchEditValue = null
                      },
                    },
                    model: {
                      value: _vm.batchEditField,
                      callback: function ($$v) {
                        _vm.batchEditField = $$v
                      },
                      expression: "batchEditField",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _vm.batchEditField.type == "text"
                    ? _c("v-text-field", {
                        attrs: {
                          maxlength:
                            _vm.batchEditField.value == "state" ? 2 : undefined,
                          dense: "",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.mapValues(
                              _vm.batchEditField,
                              _vm.batchEditValue
                            )
                          },
                        },
                        model: {
                          value: _vm.batchEditValue,
                          callback: function ($$v) {
                            _vm.batchEditValue = $$v
                          },
                          expression: "batchEditValue",
                        },
                      })
                    : _vm.batchEditField.type == "number"
                    ? _c("v-text-field", {
                        attrs: { dense: "", type: "number" },
                        on: {
                          change: function ($event) {
                            return _vm.mapValues(
                              _vm.batchEditField,
                              _vm.batchEditValue
                            )
                          },
                        },
                        model: {
                          value: _vm.batchEditValue,
                          callback: function ($$v) {
                            _vm.batchEditValue = $$v
                          },
                          expression: "batchEditValue",
                        },
                      })
                    : _vm.batchEditField.type == "boolean"
                    ? _c("v-checkbox", {
                        on: {
                          change: function ($event) {
                            return _vm.mapValues(
                              _vm.batchEditField,
                              _vm.batchEditValue
                            )
                          },
                        },
                        model: {
                          value: _vm.batchEditValue,
                          callback: function ($$v) {
                            _vm.batchEditValue = $$v
                          },
                          expression: "batchEditValue",
                        },
                      })
                    : _vm.batchEditField.type == "date"
                    ? _c("date-picker", {
                        attrs: { dense: "" },
                        on: {
                          input: function ($event) {
                            return _vm.mapValues(
                              _vm.batchEditField,
                              _vm.batchEditValue
                            )
                          },
                        },
                        model: {
                          value: _vm.batchEditValue,
                          callback: function ($$v) {
                            _vm.batchEditValue = $$v
                          },
                          expression: "batchEditValue",
                        },
                      })
                    : _vm.batchEditField.type == "time"
                    ? _c("time-picker", {
                        attrs: { dense: "" },
                        on: {
                          input: function ($event) {
                            return _vm.mapValues(
                              _vm.batchEditField,
                              _vm.batchEditValue
                            )
                          },
                        },
                        model: {
                          value: _vm.batchEditValue,
                          callback: function ($$v) {
                            _vm.batchEditValue = $$v
                          },
                          expression: "batchEditValue",
                        },
                      })
                    : _vm.batchEditField.type?.multiple
                    ? _c("v-select", {
                        staticStyle: { "min-width": "100px" },
                        attrs: {
                          items: _vm.batchEditField.type
                            ? _vm.batchEditField.type.items
                            : [],
                          "item-text": "text",
                          "item-value": "value",
                          dense: "",
                          "hide-details": "",
                          outlined: "",
                          multiple: "",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.mapValues(
                              _vm.batchEditField,
                              _vm.batchEditValue
                            )
                          },
                        },
                        model: {
                          value: _vm.batchEditValue,
                          callback: function ($$v) {
                            _vm.batchEditValue = $$v
                          },
                          expression: "batchEditValue",
                        },
                      })
                    : _c("v-select", {
                        attrs: {
                          items: _vm.batchEditField.type
                            ? _vm.batchEditField.type.items
                            : [],
                          "item-text": "name",
                          "item-value": "id",
                          dense: "",
                          outlined: "",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.mapValues(
                              _vm.batchEditField,
                              _vm.batchEditValue
                            )
                          },
                        },
                        model: {
                          value: _vm.batchEditValue,
                          callback: function ($$v) {
                            _vm.batchEditValue = $$v
                          },
                          expression: "batchEditValue",
                        },
                      }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.openStatusHelper,
            callback: function ($$v) {
              _vm.openStatusHelper = $$v
            },
            expression: "openStatusHelper",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v(" Status "),
              ]),
              _c(
                "v-card-text",
                _vm._l(_vm.fileStatuses, function (status, i) {
                  return _c(
                    "v-row",
                    { key: i, staticClass: "mt-2", attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "6" } }, [
                        _vm._v(_vm._s(status.name)),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-select", {
                            attrs: {
                              value: status.mappedTo,
                              items: _vm.statusMap,
                              "item-text": "name",
                              "item-value": "id",
                              dense: "",
                              outlined: "",
                            },
                            model: {
                              value: status.mappedTo,
                              callback: function ($$v) {
                                _vm.$set(status, "mappedTo", $$v)
                              },
                              expression: "status.mappedTo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: { click: _vm.closeStatusHelper },
                    },
                    [_vm._v(" Done ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }