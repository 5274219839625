var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("import-table", {
        ref: "importTableRef",
        attrs: {
          fileHeaders: _vm.fileHeaders,
          rows: _vm.rows,
          fields: _vm.fields,
          items: _vm.locations,
          importing: _vm.importing,
        },
        on: { mapValues: _vm.mapValues, import: _vm.importLocations },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }